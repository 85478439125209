@import "./variables.scss";
@import "./variables/fonts.scss";

body {
  font-family: $font-primary, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $text-black;
}

// // Headings
// h1,
// h2,
// h3,
// h4,
// h5,
// h6 {
//   margin: 0;
//   font-weight: 500;
// }

// h1 {
//   font-size: 3.8rem;
//   line-height: 4.8rem;

//   @include media(">tablet") {
//     font-size: 6rem;
//     line-height: 7.2rem;
//   }
// }

// h2 {
//   font-size: 2.6rem;
//   line-height: 3.2rem;

//   @include media(">tablet") {
//     font-size: 3.6rem;
//     letter-spacing: 0.1rem;
//     line-height: 4.8rem;
//   }
// }

// h3 {
//   font-size: 2.8rem;
// }

// h4 {
//   font-size: 2.4rem;
// }

// h5 {
//   font-size: 2rem;
// }

// h6 {
//   font-size: 1.8rem;
// }

// // Body copy
// p,
// ol,
// ul,
// dl,
// table {
//   font-size: 1.6rem;
// }

// p {
//   margin-bottom: 1.6rem;
//   font-size: 1.6rem;
//   font-weight: 300;
//   line-height: 2.4rem;
//   letter-spacing: -0.025rem;
// }

// // Blockquote
// blockquote {
//   margin: 3.7rem 0 6.2rem;
//   padding: 0 1.7rem 0 0;

//   @include media(">tablet") {
//     margin: 5.1rem 0 7.8rem;
//     padding: 0;
//   }

//   * {
//     font-size: 2.6rem;
//     letter-spacing: -0.025rem;
//     line-height: 4rem;
//   }

//   cite {
//     display: block;
//     margin-top: 1.5rem;
//     font-size: 1rem;
//     text-align: right;
//   }
// }

// // Lists
// ul,
// ol,
// dl {
//   text-align: left;
//   padding-inline-start: 4rem;
//   margin-bottom: 1.6rem;
//   font-size: 1.6rem;
//   font-weight: 500;
//   line-height: 2.4rem;

//   ul,
//   ol,
//   dl {
//     margin-bottom: 0;
//   }
// }

// ul {
//   list-style-type: disc;

//   li {
//     ul {
//       margin-top: 0.6rem;
//       margin-bottom: 0;
//     }
//   }
// }

// ol {
//   list-style-type: decimal;
// }

// li {
//   margin-bottom: 0.6rem;

//   &:last-of-type {
//     margin-bottom: 0;
//   }
// }

// abbr {
//   text-decoration: none;
//   border-bottom: 0.1rem dashed $grey;
// }

// // Code

// dt {
//   font-weight: bold;
// }

// code {
//   font-family: courier, monospace;
//   padding: 0.25em;
//   background: $grey;
// }

// pre {
//   text-align: left;
//   font-size: 100%;
// }

// hr {
//   height: 0;
//   color: $grey;
//   border: 1px solid $grey;
//   margin: 2em 0 1em;
// }

// kbd {
//   margin: 0 0.1em;
//   padding: 0.1rem 0.6rem;
//   border-radius: 0.3rem;
//   border: 1px solid $grey;
//   color: $black;
//   font-family: courier, monospace;
//   font-size: 1.2rem;
//   display: inline-block;
//   border-radius: 0.3rem;
//   background-color: $grey;
// }

@font-face {
  font-family: "Montserrat";
  font-display: swap;
  font-weight: 400;
  src: local("☺"),
    url("../public/font/montserrat-light-webfont.woff2") format("woff2"),
    url("../public/font/montserrat-light-webfont.woff") format("woff"),
    url("../public/font/montserrat-light-webfont.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-display: swap;
  font-weight: 300;
  src: local("☺"),
    url("../public/font/Montserrat-ExtraLight.woff2") format("woff2"),
    url("../public/font/Montserrat-ExtraLight.woff") format("woff"),
    url("../public/font/Montserrat-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-display: swap;
  font-weight: 700;
  src: local("☺"),
    url("../public/font/montserrat-medium-webfont.woff2") format("woff2"),
    url("../public/font/montserrat-medium-webfont.woff") format("woff"),
    url("../public/font/montserrat-medium-webfont.ttf") format("truetype");
}

@font-face {
  font-family: "GT-Sectra-Fine-Bold";
  font-display: swap;
  font-weight: 400;
  src: local("☺"),
    url("../public/font/GT-Sectra-Fine-Bold.woff2") format("woff2"),
    url("../public/font/GT-Sectra-Fine-Bold.woff") format("woff"),
    url("../public/font/GT-Sectra-Fine-Bold.ttf") format("truetype");
}
