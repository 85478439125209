@import "./reset.scss";
@import "./typography.scss";
@import "./print.scss";
@import "./slider.scss";
@import "./lightbox.scss";
@import "./rich-text.scss";
@import "./tag.scss";
@import "./coveo.scss";
@import "./forms.scss";
@import "./bbox.scss";

@import "./components/swiper.scss";
@import "./components/uif_forms-elements.scss";
@import "./components/uif_rich-text.scss";

@import "../components/C285_SocialShare/C285_SocialShare.module.scss";

:root {
  --atomic-font-family: "GT-Sectra-Fine-Bold", serif;
  --atomic-border-radius-md: 0;
  --atomic-text-base: 16px;
  --atomic-text-sm: 16px;
  --atomic-on-background: #001e62;
  --atomic-neutral-dark: #001e62;
  --atomic-primary: #001e62;
  --atomic-neutral-light: transparent;
}
html {
  max-width: 100%;
  font-size: 10px;
  overflow-x: hidden;
}

.pdfWaitScreen {
  width: 100vw;
  height: 100vh;
  background-color: rgb(50, 54, 57);
}

button {
  cursor: pointer;

  &:focus {
    box-shadow: 0 0 0 0.3rem $accessible-focus;
    outline: none;
  }
}

a {
  color: $white;
  text-decoration: none;
}

.preview-mode {
  z-index: 100000;
  position: absolute;
  top: 0;
  right: 0;
  background-color: red;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  gap: 0.5rem;
  padding: 1rem;
  color: white;
  font-size: 1.25rem;
  font-weight: 700;
  text-align: right;

  a {
    color: inherit;
    text-decoration: underline;
  }
}

.site {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
}

.site-content {
  box-sizing: border-box;
  flex-grow: 1;
  width: 100%;
}

.ps__rail-x {
  opacity: 1 !important;
  z-index: 5;
  pointer-events: none;

  // TODO: Review this line
  display: none !important;

  @include media(">tablet") {
    pointer-events: all;
  }
}

.slick-track {
  @include media(">tablet") {
    min-height: 450px;
  }
}

.slick-slider {
  max-width: $desktop-component-width;
  margin: auto;
}

.basicLightbox {
  .faculty-profile-carousel {
    &.full-profile-container {
      display: block;
      max-width: 100%;
      width: 1180px;
      padding: 2rem;
    }
  }

  &__placeholder {
    position: relative;

    .close {
      top: 6rem;
      right: 2rem;
      position: absolute;
      cursor: pointer;

      svg {
        fill: $projectBlue;
      }
    }
  }
}

//
//≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈
//≡≡≡≡≡≡   GLOBAL STYLES 1.0                                                                              ≡≡≡≡≡≡≡≡≡≡≡≡
//≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈≈
//

body {
  font-family: $baseFontFamily;
  color: $baseFontColour;
  overflow: hidden;
}

.wrapper {
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
}

// css added from ./global/_layout.scss
.flush-no-padding {
  margin-left: -2rem;
  margin-right: -3rem;
  overflow: hidden;
}

.half-wrap-left {
  max-width: calc(570px - 2rem);
  margin-left: auto;
}

.half-wrap-right {
  max-width: calc(570px - 2rem);
  margin-right: auto;
}

main {
  background-color: $projectBone;
  padding: 0 2rem;

  // aid for component and sub-Layout spacing
  &.highlight-border {
    .component {
      border: 0.1rem dashed orange;

      &.lbs-form {
        border: 0.2rem dashed red;
      }

      &.socialshare,
      .breadcrumb {
        border: 0;
      }

      &.socialshare {
        .social-sharing {
          border: 0;
        }
      }
    }

    .sublayout-1,
    .sublayout-2,
    .sublayout-3 {
      border: 0.2rem solid #6c3;
      position: relative;

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        color: #6c3;
        border-right: 0.2rem solid #6c3;
        border-bottom: 0.2rem solid #6c3;
        font-size: 1.4rem;
        display: inline-block;
        background-color: #fff;
        padding: 0.5rem 0.8rem 0.4rem;
      }
    }

    .sublayout-1 {
      &::before {
        content: "sublayout-1";
      }
    }

    .sublayout-2 {
      &::before {
        content: "sublayout-2";
      }
    }

    .sublayout-3 {
      &::before {
        content: "sublayout-3";
      }
    }
  }
}

.sublayout {
  margin: 4rem 0;
}

.component-wrap {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.outer-wrap {
  margin: 0 -2rem;
  padding: 4rem 2rem;
  width: calc(100% + 4rem);

  &.white {
    background-color: white;
  }

  &.white-grey-split {
    padding-top: 0;
    padding-bottom: 0;
    background-color: white;

    > .wrapper {
      > .row {
        > .base6 {
          &:last-of-type {
            position: relative;

            &::before {
              content: "";
              width: 200%;
              height: auto;
              display: block;
              background-color: $projectBone;
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
            }
          }
        }
      }
    }

    .component {
      position: relative;
    }
  }

  &.grey {
    background-color: #ebe8e5;
  }

  &.blue {
    background-color: $projectBlue;
    color: white;

    a {
      &:not(.cta) {
        color: white;
      }
    }
  }

  &.dark-blue {
    background-color: $projectDarkBlue;
  }

  &.white-blue {
    padding: 0 2rem;
    margin: 0 -2rem;
  }

  .wrapper-wide {
    width: 100%;

    &.to-edge {
      width: calc(100% + 4rem);
      margin: 0 -2rem;

      > .row {
        margin: 0;

        > .base12 {
          padding: 0;
        }
      }
    }
  }
}

.sub-navigation-two-level {
  + .white-grey-split {
    margin-top: -4rem;
  }
}

hr {
  border-bottom: 1px solid $projectBlue;
  border-top: 0;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.d-hide {
  display: none;
}

.underline {
  text-decoration: underline;
}

a {
  color: $baseAnchorColour;
  font-weight: bold;

  &:active,
  &:focus,
  &:hover {
    text-decoration: underline;
  }

  &.cta {
    text-decoration: none;
  }

  &.cta.centered {
    display: block;
    margin: 4rem auto;
    max-width: 30rem;
  }
}

h1,
h2,
h3,
h4,
h5 {
  font-family: $headingFontFamily;
  font-weight: 400;
}

.h1,
h1 {
  @include line-height(86, 76);
  font-size: 7.6rem;
  margin-bottom: 2.8rem;
}

.h2,
h2 {
  @include line-height(65, 60);
  font-size: 6rem;
  margin-bottom: 2.8rem;
}

.h3,
h3 {
  @include line-height(60, 48);
  font-size: 4.8rem;
  margin-bottom: 2.4rem;
}

.h4,
h4 {
  @include line-height(40, 36);
  font-size: 3.6rem;
  margin-bottom: 3.2rem;
}

.h5,
h5 {
  @include line-height(38, 30);
  font-size: 2.8rem;
  margin-bottom: 1.5rem;
}

.h6,
h6 {
  @include line-height(34, 24);
  font-size: 2.4rem;
  margin-bottom: 1.5rem;
}

p {
  @include line-height(24, 16);
  font-size: 1.6rem;
  margin-bottom: 1rem;

  &.small {
    @include line-height(20, 13);
    font-size: 1.3rem;
  }

  &.large {
    @include line-height(30, 20);
    font-size: 2rem;
  }

  &.tag {
    @include line-height(20, 12);
    font-size: 1.2rem;
  }

  &.label {
    @include line-height(22, 14);
    font-size: 1.4rem;
    color: $projectGrey;
    text-transform: uppercase;
    margin: 3rem 0;
    letter-spacing: 0.5px;
  }
}

ul {
  list-style-type: none;
}

ol,
ul {
  li {
    @include line-height(24, 16);
    font-size: 1.6rem;
    margin-bottom: 1rem;
  }
}

dl {
  dd,
  dt {
    @include line-height(24, 16);
    font-size: 1.6rem;
  }

  dd {
    margin-bottom: 1rem;
  }
}

.cta {
  @include line-height(24, 16);
  background-color: $projectBlue;
  color: white;
  width: 100%;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-size: 1.6rem;
  padding: 1.2rem 3.2rem;
  border: 2px solid transparent;
  font-weight: bold;
  cursor: pointer;
  border-radius: 0;
  transition: all 0.3s;

  &.loading {
    background-color: $projectDarkBone;
    border-color: $projectDarkBone;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 1.2rem 3.2rem;

    &:focus,
    &:hover {
      background-color: $projectDarkBone;
      border-color: $projectDarkBone;
      padding: 1.2rem 3.2rem;
    }
  }

  &:focus,
  &:hover {
    background-color: transparent;
    border: 2px solid $projectBlue;
    color: $projectBlue;

    &:not(.tertiary) {
      &:not(.download) {
        padding: 1rem 3.2rem 1.4rem;
      }
    }
  }

  &.tertiary.light.see-more-toggle {
    .icon-chevron {
      svg {
        transform: rotate(90deg) translateY(-50%);
        transform-origin: top;
      }
    }
    &:hover,
    &:focus {
      span:last-child {
        background-image: none;
      }
    }
  }
  &.secondary {
    background-color: transparent;
    border: 1px solid $projectBlue;
    color: $projectBlue;

    &:focus,
    &:hover {
      color: white;
      background-color: $projectBlue;
    }
  }

  &.tertiary {
    background: none;
    border: 0;
    width: auto;
    color: $projectBlue;
    font-size: 1.8rem;
    padding: 0 1rem 0 3.5rem;
    position: relative;

    svg {
      width: 1.6rem;
      height: 1.6rem;
      position: absolute;
      left: 0;
      top: calc(50% - 0.1rem);
      transform: translateY(-50%);
      fill: $projectBlue;
    }

    span {
      &:last-child {
        margin-left: -1rem;
        transition: margin 0.3s;
        display: inline-block;
      }

      &.icon-calendar {
        svg {
          height: 2rem;
          width: 2rem;
          top: calc(50% - 0.3rem);
        }
      }

      &.icon-play {
        svg {
          height: 4.2rem;
          width: 4.2rem;
        }
      }
    }

    &.play {
      padding: 0 1rem 0 6.5rem;
    }

    &:focus,
    &:hover {
      padding: 0 0 0 3.5rem;

      &.play {
        padding: 0 0 0 6.5rem;
      }

      span {
        &.icon-arrow {
        }

        &:last-child {
          transform: none;
          margin-right: 0;
          margin-left: 0;
          background-image: linear-gradient(90deg, $projectBlue, $projectBlue);
          background-repeat: no-repeat;
          background-position: 0 100%;
          background-size: 100% 0.1rem;
        }
      }

      // &::after {
      //     position: absolute;
      //     content: '';
      //     bottom: 0;
      //     width: calc(100% - 3.5rem);
      //     height: 1px;
      //     background-color: black;
      //     left: 0;
      //     margin-left: 3.5rem;
      // }
    }
  }

  &.download:not(.tertiary) {
    position: relative;
    border: 1px solid $projectBlue;
    background: transparent;
    display: flex;
    padding: 0;
    color: $projectBlue;
    transition: padding 0.3s;

    > span {
      &:first-child {
        position: relative;
        width: 4.8rem;
        height: 4.8rem;
        flex-shrink: 0;

        svg {
          width: 1.8rem;
          height: 1.8rem;
          fill: $projectBlue;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      &:last-child {
        border-left: 1px solid #001e62;
        padding: 1.2rem;
        flex-grow: 1;
        transition: padding 0.3s;
      }
    }

    &:focus,
    &:hover {
      background-color: $projectBlue;
      // border: 1px solid white;
      color: white;

      > span {
        &:first-child {
          svg {
            fill: white;
          }
        }

        &:last-child {
          border-left: 1px solid white;
          padding-bottom: 1.4rem;
          padding-top: 1rem;
        }
      }
    }
  }

  &.light {
    background-color: $projectBone;
    color: $projectBlue;
    border: 2px solid $projectBone;

    &:focus,
    &:hover {
      background-color: transparent;
      border: 2px solid $projectBone;
      color: $projectBone;
    }

    &.secondary {
      background-color: transparent;
      color: $projectBone;
      border: 1px solid $projectBone;

      &:focus,
      &:hover {
        background-color: $projectBone;
        color: $projectBlue;
      }
    }

    &.tertiary {
      background: none;
      border: 0;
      color: $projectBone;
      font-size: 1.8rem;

      svg {
        fill: $projectBone;
      }

      span {
        &.icon-play {
          svg {
            > g {
              > g {
                stroke: white;
              }
            }
          }
        }
      }

      &:focus,
      &:hover {
        span {
          &.icon-arrow {
            &::after {
              background-color: $projectBone;
            }
          }

          &:last-child {
            background-image: linear-gradient(
              90deg,
              $projectBone,
              $projectBone
            );
          }
        }

        &::after {
          background-color: $projectBone;
        }
      }

      &[disabled] {
        color: $projectDarkBone;
        pointer-events: none;

        svg {
          fill: $projectDarkBone;
        }
      }
    }

    &.download {
      background-color: transparent;
      color: $projectBone;

      > span {
        &:first-child {
          svg {
            fill: $projectBone;
          }
        }

        &:last-child {
          border-left: 1px solid $projectBone;
        }
      }

      &:focus,
      &:hover {
        background-color: $projectBone;
        // border: 1px solid $projectBlue;
        color: $projectBlue;

        > span {
          &:first-child {
            svg {
              fill: $projectBlue;
            }
          }

          &:last-child {
            border-left: 1px solid $projectBlue;
          }
        }
      }
    }
  }

  &.inverted {
    &.secondary {
      background-color: white;
      border-color: white;
      color: $projectBlue;

      &:focus,
      &:hover {
        background-color: transparent;
        border-color: white;
        color: white;
      }

      .button-wrapper {
        margin: 3rem 1rem;
      }
    }
  }

  &.cta-back {
    font-size: 1.8rem;
    line-height: 2.2rem;
    margin-top: 0;

    .icon-arrow-long {
      svg {
        transform: rotate(180deg);
        top: 0.2rem;
      }
    }
  }

  &[disabled],
  &.disabled {
    pointer-events: none;
    opacity: 0.7;
  }
}

.rich-text {
  .cta {
    width: auto;
    &.primary {
      color: #fff;
      border-width: 1px;
      &:hover,
      &:focus,
      &:visited:hover {
        outline: none;
        color: $projectBlue;
        border-color: $projectBlue;
        border-bottom: 1px solid $projectBlue;
      }
    }
  }
}

input.cta {
  &:disabled {
    color: $white;
  }
}

.cta-with-message {
  display: flex;

  .message-wrapper {
    @include message-popup;
    position: relative;
    display: flex;
    align-items: center;
    margin-left: 1.6rem;
    padding: 0;

    &::before {
      right: 0.5rem;
      background: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22\a%20%20%20viewBox%3D%222%202%2020%2020%22%20enable-background%3D%22new%200%200%2030%2030%22%20xml%3Aspace%3D%22preserve%22%3E\a%3Cpath%20fill%3D%22%237f8eb0%22%20d%3D%22M11%2017h2v-6h-2v6zm1-15C6.48%202%202%206.48%202%2012s4.48%2010%2010%2010%2010-4.48%2010-10S17.52%202%2012%202zm0%2018c-4.41%200-8-3.59-8-8s3.59-8%208-8%208%203.59%208%208-3.59%208-8%208zM11%209h2V7h-2v2z%22%2F%3E\a%3C%2Fsvg%3E");
    }

    .message {
      color: $projectBlue;
      bottom: 5rem;
      right: 0;

      &::after {
        right: 0;
      }
    }

    &.light {
      &:hover {
        &::before {
          background: url("data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%0A%3Csvg%20width%3D%2220px%22%20height%3D%2220px%22%20viewBox%3D%220%200%2020%2020%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Ctitle%3EError%3C%2Ftitle%3E%0A%20%20%20%20%3Cpath%20fill%3D%22%23ffffff%22%20d%3D%22M9%2C13%20L11%2C13%20L11%2C15%20L9%2C15%20L9%2C13%20Z%20M9%2C5%20L11%2C5%20L11%2C11%20L9%2C11%20L9%2C5%20Z%20M0%2C10%20C0%2C15.52%204.47%2C20%209.99%2C20%20C15.52%2C20%2020%2C15.52%2020%2C10%20C20%2C4.48%2015.52%2C0%209.99%2C0%20C4.47%2C0%200%2C4.48%200%2C10%20Z%22%20id%3D%22path-1%22%3E%3C%2Fpath%3E%0A%3C%2Fsvg%3E");
        }

        .message {
          color: $projectBlue;
        }
      }
    }
  }
}

.cta-container {
  &.blue {
    .cta {
      // sass-lint:disable placeholder-in-extend
      // @extend .cta.light;
    }
  }
}

table {
  width: 100%;
  margin-bottom: 2.2rem;
  border-collapse: collapse;

  thead,
  tbody {
    border: 1px solid rgba($projectBlue, 0.25);
  }

  caption {
    line-height: 2.2rem;
    display: table-caption;
    font-family: $baseFontFamily;
    font-size: 1.4rem;
    font-weight: bold;
    margin: 1.7rem 0;
    text-align: left;
  }

  td,
  th {
    vertical-align: top;
    text-align: left;
    border-right: 0.1rem solid rgba($projectBlue, 0.25);
  }

  td {
    font-size: 1.6rem;
    padding: 1.4rem 2rem;
    line-height: 2.4rem;
    border-top: 0;
    border-bottom: 0.1rem solid rgba($projectBlue, 0.25);
  }

  thead {
    th {
      background: $projectLightGrey;
      color: $projectBlue;
      font-size: 1.6rem;
      padding: 1.4rem 2.1rem 1.5rem;
      border-top: 0;
      border-bottom: 0.1rem solid $projectBlue;
      font-family: $baseFontFamily;
    }

    + tbody {
      th {
        border-bottom: 0.1rem solid rgba($projectBlue, 0.25);
      }
    }
  }

  tbody {
    th {
      background: transparent;
      color: $projectBlue;
      font-size: 1.6rem;
      padding: 1.8rem 2.1rem 1.6rem;
      border-top: 0;
      border-bottom: 0.1rem solid $projectBlue;
      font-family: $baseFontFamily;
      font-weight: normal;
    }

    td {
      color: $projectBlue;
    }
  }

  &.uif-matrix {
    margin-bottom: 4rem;

    td,
    th {
      padding: 0.6rem 1.2rem;
    }

    tr {
      th {
        background-color: $projectRed;
        color: white;
      }

      &:nth-child(even) {
        background-color: $projectRed;
      }
    }
  }

  &.borderless {
    border: 0;

    thead,
    tbody {
      border: 0;
    }

    tr {
      td,
      th {
        border-right: 0;
      }

      &:last-child {
        td,
        th {
          border-bottom: 0;
        }
      }
    }

    thead {
      tr {
        th {
          border-bottom: 0.1rem solid $projectBlue;
        }

        &:last-child {
          th {
            border-bottom: 0.1rem solid $projectBlue;
          }
        }
      }
    }
  }
}
// SKIP TO CONTENT STYLES */

.skip {
  left: -999rem;
  position: absolute;

  &:active,
  &:focus {
    background: #01a7e3;
    top: 0;
    left: 0;
    color: #ffffff;
    font-size: 1.6rem;
    display: inline-block;
    padding: 0.4rem;
    border: 0.1rem solid $projectWhite;
    white-space: nowrap;
    text-align: center;
    font-weight: 700;
  }
}
// ADDED IN HERE TO MAKE IT ACCESSIBLE GLOBALLY

.flush {
  margin-left: -2rem;
  margin-right: -2rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

// SITEMAP STYLE
.sitemap {
  margin: 6rem auto;

  h2 {
    @include line-height(40, 36);
  }
}

// COMPACT WEB */
@media #{$compact-web} {
}
// TABLET PORTRAIT */
@media #{$tablet} {
}
// MOBILE LANDSCAPE */
@media #{$mobile-landscape} {
}
// MOBILE PORTRAIT */
@media #{$mobile} {
  .slick-slider {
    max-width: 100%;
    margin: auto;
  }

  .half-wrap-left {
    max-width: 100%;
  }

  .half-wrap-right {
    max-width: 100%;
  }

  .outer-wrap {
    &.white-grey-split {
      padding-top: 0;
      padding-bottom: 0;
      background-color: white;

      > .wrapper {
        > .row {
          > .base6 {
            &:last-of-type {
              position: relative;

              &::before {
                left: -2rem;
                width: 150%;
              }
            }
          }
        }
      }

      .component {
        position: relative;
      }

      &.white-blue {
        padding: 0 1rem;
      }
    }
  }

  .m-hide {
    display: none;
  }

  .d-hide {
    display: block;
  }

  .h1,
  h1 {
    @include line-height(54, 46);
    font-size: 4.6rem;
  }

  .h2,
  h2 {
    @include line-height(48, 40);
    font-size: 4rem;
  }

  .h3,
  h3 {
    @include line-height(36, 28);
    font-size: 2.8rem;
  }

  .h4,
  h4 {
    @include line-height(36, 28);
    font-size: 2.8rem;
  }

  .h5,
  h5 {
    @include line-height(36, 28);
    font-size: 2.8rem;
  }

  // responsive table with header */
  .table {
    position: relative;
    overflow: hidden;
    display: block;
    width: 100%;
    white-space: nowrap;
  }
}

.coveo-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

// In order to remove peer-dependency on jQuery we have to add this scss to our global styles
// https://react-slick.neostack.com/docs/get-started

.slick-slider {
  position: relative;

  display: block;
  box-sizing: border-box;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;

  &.faculty-profile-carousel {
    overflow: hidden;
  }
}

.slick-list {
  position: relative;

  display: block;
  //   overflow: visible;

  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;

  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;

  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;

  // wrapper of the carousel item content
  & > div {
    // background: white;
    margin: 0 2rem;
  }

  & > .no-gap {
    margin: 0;
  }
}

.programme-comparison-table.slick-initialized .slick-slide {
  & > div {
    margin: 0;
  }
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;

  height: auto;

  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

@charset "UTF-8";

/* Slider */
.slick-loading .slick-list {
  background: #fff center center no-repeat;
}

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;

  position: absolute;
  top: 50%;

  display: block;

  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);

  cursor: pointer;

  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;

  opacity: 0.75;
  color: white;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}

[dir="rtl"] .slick-prev {
  right: -25px;
  left: auto;
}

.slick-prev:before {
  content: "←";
}

[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}

[dir="rtl"] .slick-next {
  right: auto;
  left: -25px;
}

.slick-next:before {
  content: "→";
}

[dir="rtl"] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.slick-dots li span,
.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 1.2rem;
  height: 1.2rem;
  padding: 1rem;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.slick-dots li span:hover,
.slick-dots li button:hover,
.slick-dots li span:focus,
.slick-dots li button:focus {
  outline: none;
}

.slick-dots li span:hover:before,
.slick-dots li button:hover:before,
.slick-dots li span:focus:before,
.slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li span:before,
.slick-dots li button:before {
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  border-radius: 50%;
  border: 1px solid $projectBlue;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1.2rem;
  height: 1.2rem;
  content: "";
  text-align: center;
  opacity: 1;
  //   color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active span:before,
.slick-dots li.slick-active button:before {
  background-color: $projectBlue;
  opacity: 1;
}

.blue {
  .slick-dots li button:before {
    border: 1px solid $projectWhite;
  }

  .slick-dots li.slick-active button:before {
    background-color: $projectWhite;
  }
}

.blue {
  .scrollbar-container {
    .ps__rail-y {
      .ps__thumb-y {
        background-color: $projectWhite;
      }
      &:hover {
        .ps__thumb-y {
          background-color: $projectWhite;
        }
      }
      &::after {
        background-color: $projectWhite;
      }
    }
  }
}

// React PerfectScrollbar
.scrollbar-container {
  &.ps:hover {
    .ps__rail-y {
      opacity: 1;
      background: none;
    }
  }

  .ps__rail-y {
    &:hover {
      .ps__thumb-y {
        width: 8px;
        background-color: $projectBlue;
      }

      &::after {
        right: 5px;
      }
    }

    .ps__thumb-y {
      background-color: $projectBlue;
    }

    &::after {
      background-color: $projectBlue;
      z-index: 0;
      content: "";
      position: absolute;
      height: 100%;
      width: 1px;
      right: 4px;
    }
  }
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;

  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
  outline: 0;
  outline-offset: 0;
}

.error-message {
  padding: 20px 0;
  border-top: 1px solid #e2ded9;
  border-bottom: 1px solid #e2ded9;
  padding-left: 30px;
  position: relative;

  p {
    margin: 0;
  }

  &::before {
    content: "";
    width: 2rem;
    height: 2rem;
    display: inline-block;
    left: 0px;
    top: 21px;

    position: absolute;
    background: url("data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%0A%3Csvg%20width%3D%2220px%22%20height%3D%2220px%22%20viewBox%3D%220%200%2020%2020%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Ctitle%3EError%3C%2Ftitle%3E%0A%20%20%20%20%3Cpath%20fill%3D%22%23c8103e%22%20d%3D%22M9%2C13%20L11%2C13%20L11%2C15%20L9%2C15%20L9%2C13%20Z%20M9%2C5%20L11%2C5%20L11%2C11%20L9%2C11%20L9%2C5%20Z%20M0%2C10%20C0%2C15.52%204.47%2C20%209.99%2C20%20C15.52%2C20%2020%2C15.52%2020%2C10%20C20%2C4.48%2015.52%2C0%209.99%2C0%20C4.47%2C0%200%2C4.48%200%2C10%20Z%22%20id%3D%22path-1%22%3E%3C%2Fpath%3E%0A%3C%2Fsvg%3E");
  }
}

// React search dropdown overrides
.react-autosuggest__container {
  position: relative;
}
.react-autosuggest__input {
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  padding: 16px 16px 13px 16px !important;
  border-radius: 4px;
}
.react-autosuggest__input--focused {
  outline: none;
}
.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.react-autosuggest__suggestions-container {
  display: none;
}
.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 54px;
  width: 100%;
  border: 1px solid #001e62;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}
.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}
.react-autosuggest__suggestion--highlighted {
  background-color: #f5f5f5 !important;
  color: #001e62;
}
